import React, { Component } from 'react';

import './FeedbackForm.scss';

export default class FeedbackForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      validated: false,
      status: '',
      email_format: true
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.emailFormat = this.emailFormat.bind(this);
  }

  emailFormat(event) {
    event.preventDefault();
    const filter = /^([a-zA-Z0-9_.])+@(([a-zA-Z0-9])+\.)+([a-zA-Z0-9]{2,4})+$/;
    filter.test(event.target.value)
      ? this.setState({ email_format: true })
      : this.setState({ email_format: false })
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      xhr.open(form.method, form.action);
      xhr.setRequestHeader('Accept', 'application/json');
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return;
        if (xhr.status === 200) {
          this.setState({ status: 'SUCCESS' });
          setTimeout(() => {
            this.setState({ status: '', validated: false });
            form.reset();
          }, 2000);
        } else {
          this.setState({ status: 'ERROR' });
        }
      };
      xhr.send(data);
    }
    this.setState({ validated: true });
  }

  render () {
    const { modalIsOpen, setModal } = this.props;
    const { validated, status, email_format } = this.state;

    return (
      <>
        <form
          noValidate
          action="https://formspree.io/meqgdgdj"
          onSubmit={e => this.handleSubmit(e)}
          method="POST"
          className={`form ${validated ? 'validated' : ''}`}
        >

          <div className="form-group">
            <h3 className="h3">Send request</h3>
          </div>

          <div className="form-group">
            <input
              type="text"
              name="name"
              id="name"
              placeholder="Your Name"
              required
            />
            <span className="invalid-message">Name is required</span>
          </div>

          <div className="form-group">
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Your Email"
              onChange={e => this.emailFormat(e)}
              required
            />
            <span className="invalid-message">Email is required</span>
            {
              !email_format && <span className="invalid-format-message">Invalid Format</span>
            }
          </div>

          <div className="form-group">
            <textarea
              name="message"
              id="message"
              placeholder="Your message"
              maxLength="200"
              required
            />
            <span className="invalid-message">Message is required</span>
          </div>

          <div className="form-group">
            <button type="submit" className="btn">
              {status === 'SUCCESS' ? 'Sending...' : 'Submit'}
            </button>
          </div>

        </form>
        {
          status === 'SUCCESS' && (
            <div className="success-message">
              <h2 className="h2">Thanks for contacting me!</h2>
              <h4 className="h4">Will get in touch with you shortly</h4>
              <span className="close-btn--success" onClick={() => {setModal(!modalIsOpen)}}>Close Modal</span>
            </div>
          )
        }
      </>
    )
  }

}
