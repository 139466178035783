import React from 'react';

import './AnimatedCore.scss';

const AnimatedCore = () => (
  <div className="animated-core">
    <div className="circle"/>
    <div className="circle"/>
    <div className="circle"/>
    <div className="circle"/>
    <div className="circle"/>
    <div className="circle"/>
  </div>
);

export default AnimatedCore;
