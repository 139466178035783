import React, { useState } from 'react';
import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faTelegramPlane } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faTimes, faDownload } from '@fortawesome/free-solid-svg-icons';

import FeedbackForm from '../FeedbackForm';

import './SocialLinks.scss'

const SocialLinks = () => {
  const [modalIsOpen, setModal] = useState(false);

  return (
    <>
      <nav className="social-links">
        <a href="https://www.linkedin.com/in/daniil-alekseev/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
        <a href="https://t.me/danil_alexeev/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTelegramPlane} />
        </a>
        <span onClick={() => { setModal(!modalIsOpen) }} className="modal-btn">
          <FontAwesomeIcon icon={faEnvelope} />
        </span>
      </nav>
      <a href="https://docs.google.com/document/d/1VyZxnp38EL_Id6tJGBAp9dLvp55sk_D7OoaHjStQGJA/edit?usp=sharing" target="_blank" rel="noopener noreferrer" className="cv-download">
        <FontAwesomeIcon icon={faDownload} />
        <span>Download CV</span>
      </a>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => { setModal(!modalIsOpen) }}
        contentLabel="Feedback modal"
        className="feedback-modal"
        ariaHideApp={false}
      >
        <span
          className="close-btn"
          onClick={() => { setModal(!modalIsOpen) }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <FeedbackForm setModal={setModal} modalIsOpen={modalIsOpen} />
      </Modal>
    </>
  )
};

export default SocialLinks;
