import React from 'react';

import AnimatedCore from '../components/AnimatedCore';
import SocialLinks from '../components/SocialLinks';

const HomePage = () => (
  <section id="home-page">

    <AnimatedCore />

    <SocialLinks />

    <span className="actual-year">{(new Date().getFullYear())}</span>

  </section>
);

export default HomePage;
