import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import HomePage from './pages/Home';
import ContactsPage from './pages/Contacts';

export const useRoutes = () => (
  <Switch>
    <Route path="/" exact>
      <HomePage />
    </Route>
    <Route path="/contacts" exact>
      <ContactsPage />
    </Route>
    <Redirect to="/" />
  </Switch>
);
